import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import LoadingPage from '../../common/LoadingPage';
import Header from '../../layout/Header';
import TestDetail from './TestDetail';
import useTest from '../../../hooks/useTest';

// type Props = {
//   tags?: SelectOption[];
// };

const TestDetailContainer = () => {
  const { itemId } = useParams();

  const { data, refetch, save, del, isDeleting, key } = useTest(itemId || '');

  return (
    <>
      <Header title="Test" />
      <Routes>
        <Route
          path="/"
          element={
            data ? (
              <TestDetail
                key={key}
                data={data}
                save={save}
                // tags={tags}
                refetch={refetch}
                del={del.bind(null, data.ID)}
                isDeleting={isDeleting}
              />
            ) : (
              <LoadingPage />
            )
          }
        />
      </Routes>
    </>
  );
};

export default TestDetailContainer;
